/*=========================================================================================
  File Name: progress.scss
  Description: Extended bootstrap progress bar scss.
  ----------------------------------------------------------------------------------------
  Item Name: colshab
  Author: Mohammad Nmeri
  Author :Mohammad Nmeri
  ----------------------------------------------------------------------------------------
==========================================================================================*/

.progress {
  // border radius to set for stacked bars
  .progress-bar + .progress-bar:not(:last-child) {
    border-radius: 0;
  }
  .progress-bar:last-child {
    border-top-right-radius: $progress-border-radius;
    border-bottom-right-radius: $progress-border-radius;
  }
}
