// ================================================================================================
//     File Name: type.scss
//     Description: Type pages custom scss
//     ----------------------------------------------------------------------------------------------
//     Item Name: colshab
//     Author: Mohammad Nmeri
//     Author :Mohammad Nmeri
// ================================================================================================

// text heading color
.text-body-heading {
  color: $headings-color;
  &:hover {
    color: inherit !important;
  }
}
// text body hover color if have link
.text-body {
  &[href]:hover {
    color: $link-hover-color !important;
  }
}
